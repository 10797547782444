/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

// Libraries
import parse from 'html-react-parser'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Requirements from 'components/Requirements'
import ParseContent from 'components/shared/ParseContent'
import Review from 'components/Review'
import PriceIndication from 'components/PriceIndication'
import Blog from 'components/Blog'
import RatingAlt from 'components/Reviews/RatingAlt'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import ImageBackground from 'components/elements/ImageBackground'
import HeroDescription from 'components/elements/HeroDescription'
import BlockDefault from 'components/elements/BlockDefault'
import ButtonAnimateTruck from 'components/elements/ButtonAnimateTruck'
import BackgroundDescription from 'components/elements/BackgroundDescription'

// Data
import blogInfo from 'src/graphql/blogInfo'
import Infographic from '../components/Infopgrahic/Infographic'

const Intro = styled(BlockDefault)`
  @media (min-width: 992px) {
    margin-top: -150px;

    & h2 {
      font-size: ${(props) => props.theme.font.size.xl};
      font-weight: ${(props) => props.theme.font.weight.s};

      & strong {
        font-weight: ${(props) => props.theme.font.weight.xl};
      }
    }
  }

  @media (max-width: 991px) {
    margin-top: -100px;
  }
`

const FeaturedImage = styled(ImageBackground)`
  padding-top: 50%;
  border-radius: 35px;
  overflow: hidden;
`

const Block = styled(BlockDefault)`
  @media (min-width: 992px) {
    margin-top: -200px;
  }

  @media (max-width: 991px) {
    margin-top: -50px;
  }

  @media (max-width: 575px) {
    margin-top: -35px;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    color: ${(props) => props.theme.color.text.contrast};
  }
`

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path

      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        banner_block {
          title
          button {
            title
            url
          }
          button_info
          image_banner_block {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        terms {
          title
          usp {
            relation
          }
        }

        intro {
          background_description
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          description
        }

        review {
          title
          rating
          description
          background_description
        }

        outro {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          description
        }

        blog {
          related
          background_description
        }

        show_infographic
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => (
  <Layout>
    {acf &&
      acf.banner &&
      acf.banner.image &&
      acf.banner.image.localFile &&
      acf.banner.image.localFile.childImageSharp &&
      acf.banner.image.localFile.childImageSharp.openGraph &&
      acf.banner.image.localFile.childImageSharp.openGraph.src && (
        <SEO
          yoast={yoast}
          path={path}
          image={{
            url:
              acf.banner.image.localFile &&
              acf.banner.image.localFile.childImageSharp.openGraph.src,
          }}
        />
      )}

    {acf &&
      acf.banner_block &&
      acf.banner_block.image_banner_block &&
      acf.banner_block.image_banner_block.localFile &&
      acf.banner_block.image_banner_block.localFile.childImageSharp &&
      acf.banner_block.image_banner_block.localFile.childImageSharp.openGraph &&
      acf.banner_block.image_banner_block.localFile.childImageSharp.openGraph
        .src && (
        <SEO
          yoast={yoast}
          path={path}
          image={{
            url:
              acf.banner.image.localFile &&
              acf.banner.image.localFile.childImageSharp.openGraph.src,
          }}
        />
      )}

    <section>
      <HeroDefault
        small
        image={acf.banner.image || acf.banner_block.image_banner_block}
      >
        <HeroDescription className="d-flex align-items-center justify-content-start h-100">
          {parse(acf.banner.title)}
        </HeroDescription>
      </HeroDefault>
    </section>

    <section>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-1" />
          <div className="col-lg-10">
            <Intro bigBorderRadius>
              <div className="px-lg-4 py-lg-2">
                <div className="row">
                  <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-lg-start">
                    {parse(acf.banner_block.title)}
                  </div>
                  <div className="col-md-6 text-center mt-4 mt-md-0 d-flex align-items-center justify-content-center justify-content-lg-end">
                    <div>
                      <RatingAlt noTitle className="pb-lg-0 pb-4" />
                      <div className="mt-4">
                        <ButtonAnimateTruck
                          to={acf.banner_block.button.url}
                          type="next"
                        >
                          {acf.banner_block.button.title}
                        </ButtonAnimateTruck>
                        <div className="mt-3">
                          {acf.banner_block.button_info}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Intro>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container mt-lg-5">
        <BackgroundDescription
          className="mt-3"
          content={acf.intro.background_description}
          relative
        />

        <div className="row">
          <div className="col-lg-10">
            <FeaturedImage src={acf.intro.image} />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-5" />
          <div className="col-lg-7">
            <Block bigBorderRadius>
              <Content content={acf.intro.description} />
            </Block>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container mt-5 py-5">
        <div>{parse(acf.terms.title)}</div>
        <div className="mt-5">
          <Requirements ids={acf.terms.usp.map(({ relation }) => relation)} />
        </div>
      </div>
    </section>

    {acf.review && acf.review.title && (
      <section className="pt-5">
        <div className="container">
          <div className="row flex-wrap-reverse">
            <div className="col-lg-8">
              <BackgroundDescription
                className="mt-3"
                content={acf.review.background_description}
                relative
              />
            </div>
            <div className="col-lg-4">
              <PriceIndication noInfo />
            </div>
          </div>
        </div>
        <Review fields={acf.review} />
      </section>
    )}

    <section>
      <div className="container mt-lg-5 py-5">
        <div className="row">
          <div className="col-lg-2" />
          <div className="col-lg-10">
            <FeaturedImage src={acf.outro.image} />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-7">
            <Block bigBorderRadius>
              <Content content={acf.outro.description} />
            </Block>
          </div>
          <div className="col-lg-5" />
        </div>
      </div>
    </section>

    <section>
      <div className="container mb-lg-5 pb-5">
        <div className="row">
          <div className="col-lg-8" />
          <div className="col-lg-4">
            <RatingAlt noTitle className="pb-lg-0 pb-4" />
            <div className="mt-4">
              <PriceIndication />
            </div>
          </div>
        </div>
      </div>
    </section>

    {acf.blog && acf.blog.related && acf.blog.related.length > 0 && (
      <section>
        <div className="container mb-lg-5 pb-5">
          {acf.blog.background_description && (
            <BackgroundDescription
              content={acf.blog.background_description}
              relative
            />
          )}
          <Blog
            ids={acf.blog.related}
            fields={blogInfo.get()}
            hideMore
            hideFilters
          />
        </div>
      </section>
    )}

    {acf.show_infographic && <Infographic />}
  </Layout>
)

export default PageTemplate
